import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from 'axios'

const UIT = (props) => {
    let [data, setData] = useState("")
    let [loaded, setLoaded] = useState(false)
    const lang = useSelector((state) => state.general.lang);
    const translate = useSelector((state) => state.general.translate);
    const options = {  day: 'numeric' , month: 'numeric', year: 'numeric'};
    
    useEffect(() => {
        axios.get(`${props.uit_data['@id']}`)
        .then((res) => {
            setData(res.data)
            setLoaded(true)
        })
    },[])


    return (
        loaded && 
        // <a href={data.sameAs ? data.sameAs['0'] : ""} target="_blank" rel="noopener noreferer" className="uit_item">
        //     {console.log(data.sameAs)}
        //     <figure><img src={data.image ? data.image : props.data.logo[`png_${lang}`]}/></figure>

        //     <h3 title={data.name[lang]}>{data.name[lang]}</h3>
        // </a>
        <div className="uit_item">
        <div className="uit_item_image">
          <img src={data.image ? data.image : props.data.logo[`png_${lang}`]} alt="Item Image" />
             {console.log(data)}
             {console.log()}
        </div>
        <div className="uit_item_details">
          <h2>{data.name[lang]}</h2>
          <span>{`${new Date(data.availableFrom).toLocaleDateString('fr-FR', options)} ${translate("to").toLowerCase()}  ${data.availableTo ? new Date(data.availableTo).toLocaleDateString('fr-FR', options) === '01/01/2100' ? '' : new Date(data.availableTo).toLocaleDateString('fr-FR', options) : ""}`}</span>
          <p>{data.description ? data.description[lang] : ""} </p>
          <a href={data.sameAs ? data.sameAs['0'] : ""} target="_blank" rel="noopener noreferer">{translate('read_more')}</a>
        </div>
      </div>
    )
}

export default UIT