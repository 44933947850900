import Map from "@identitybuilding/idb-react-100-percent-local"

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";
import Navigation from "./Navigation";

const Main = (props) => {
  const history = useHistory()
  const lang = useSelector((state) => state.general.lang);

  const goToPage = (e) => {

    let municipalityName = e["Name" + lang.toUpperCase()] ? e["Name" + lang.toUpperCase()].toLowerCase()
      .replace(" (stad)", '')
      .replace(" (ville)", '')
      .replace(" (stadt)", '')
      .replace(" city", '')
      .replace(/\ /g, '')
      .replace("'", '')
      .replace("lalouviere", "la louvière")
      .replace("lacalamine", "la calamine")
      .replace("labruyere", "la-bruyere")
      .replace("lahulpe", "la hulpe")
      .replace("fontaine-lévêque", 'fontaine-levêque')
      :
      e.name
        .replace(" (stad)", '')
        .replace(" (ville)", '')
        .replace(" (stadt)", '')
        .replace(" city", '')
        .replace(/\ /g, '')
        .replace("'", '')
        .replace("lalouviere", "la louvière")
        .replace("lacalamine", "la calamine")
        .replace("labruyere", "la-bruyere")
        .replace("lahulpe", "la hulpe")
        .replace("fontaine-lévêque", 'fontaine-levêque')
    if (municipalityName === 'sgravenbrakel') { municipalityName = 's-gravenbrakel' }
    if (municipalityName === 'mont-de-lenclus') { municipalityName = 'montdelenclus' }
    if (municipalityName === 'lesbonsvillers') { municipalityName = 'les-bons-villers' }
    if (window.location.hostname === 'localhost') {
      history.push(`/${municipalityName.toLowerCase()}`);
    }
    else {
      window.open(`https://${window.location.hostname}/${municipalityName.toLowerCase()}`)
    }


  }

  return (
    <div>
      <Navigation lang={lang} />
      <Map lang={lang} MyCity={true} getData={(e) => goToPage(e)} hideLoginButton={true} />
    </div>
  )
}

export default Main